import React from 'react';
import { Button } from '~/components/buttons/Button';
import { ErrorState } from '~/components/feedbacks/ErrorState';
import { alignCss } from '~/styles/alignCss';
import { textCss } from '~/styles/textCss';
import { globalPathBuilder } from '~/constants/globalPathBuilder';

export type Props = {
  onClickBack: () => void;
  title: string;
};

export const TESTID = {
  BACK: 'BACK',
} as const;

export const MESSAGE = {
  ERROR_MESSAGE: '',
  BACK: '戻る',
} as const;

export const ErrorPage: React.FC<Props> = (props: Props) => (
  <div>
    <div css={alignCss({ align: 'center' })}>
      <ErrorState message={props.title} />
    </div>

    <div css={alignCss({ align: 'center', mt: 2 })}>
      <Button fontSize="s" buttonHeight={32} onClick={props.onClickBack} testId={TESTID.BACK}>
        {MESSAGE.BACK}
      </Button>
    </div>

    <div css={[alignCss({ align: 'center', mt: 2 }), textCss({ size: 's' })]}>
      <a href={globalPathBuilder.login}>ログインページへ</a>
    </div>
  </div>
);
