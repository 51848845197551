import React from 'react';
import { ErrorPage } from '~/components/pages/other/ErrorPage';
import { getSingleActionLayout } from '~/components/pages/other/getSingleActionLayout';
import { NextPageWithLayout } from '~/types/layout';
import { useRouter } from 'next/router';

const Page: NextPageWithLayout = () => {
  const router = useRouter();
  return <ErrorPage onClickBack={() => router.back()} title="404 ページが存在しません" />;
};

Page.Layout = getSingleActionLayout();

Page.config = {
  pageTitle: '404',
};

export default Page;
